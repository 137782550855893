export const queryKeys = {
    EYEABLE_RULES: 'EyeAbleRules',
    EYEABLE_RULES_META: 'EyeAbleRulesMeta',
    WCAG_RULES: 'WCAGRules',
    WCAG_RULES_META: 'WCAGRulesMeta',
    PDF_ERROR_TEXT: 'pdfErrorText',
    FOUND_URLS: 'foundUrls',
    PDFS: 'pdfs',
    USER_PDFS: 'userPdfs',
    REPORT: 'report',
    HISTORY: 'history',
    PAGE_DETAIL_REPORT: 'pageDetailReport',
    SIGNUP: 'signup',
    FOUND_URLS_HISTORY: 'foundUrlsHistory',
    NOTIFICATIONS: 'notifications',
    LIGHTHOUSE: 'lighthouse',
    URL_MAP: 'urlMap',
    LIGHTHOUSE_SUMMARY: 'lighthouseSummary',
    SPELL_CHECKING: 'spellChecking',
    SPELL_CHECKING_READABILITY_SCORE: 'spellCheckingReadabilityScore',
    SPELL_CHECKING_HISTORY: 'spellCheckingHistory',
    UPDATE_USER: 'updateUser',
    GENERATOR_FILES: 'generatorFiles',
    ADMIN_SEARCH: 'adminSearch',
    FONTS: 'fonts',
    ORG_FILES_CONTENTS: 'orgFilesContents'
};

export const demoEntry = {
    id: 451,
    domain: 'audit.zugang4all.de',
    cdnDirectory: 'audit.zugang4all.de',
    conformanceTarget: 'AAA + Best Practice',
    complianceTarget: 'AAA + Best Practice'
};

export const demoUser = {
    id: 1083,
    apiKey: '4bYhW6401da3bnP5',
    role: 'customer',
    surname: 'DEMO',
    name: 'MODE',
    company: 'Demo Mode Company',
    email: 'info@eye-able.com',
    phone: '+49 176 64103397',
    primaryOrganisation: 1327,
    isInfinite: true,
    fullName: 'DEMO MODE',
    firstName: 'DEMO',
    lastName: 'MODE',
    hasPassword: true,
    tokenExpires: 4099852799 // 2099 in unix timestamp
};

export const demoModeConfig = {
    creationDate: '2023-04-21T11:08:44.853Z',
    enableQueryParameters: true,
    targetLang: '',
    urlsToCheck: [],
    regex: ['.*blockedUrls'],
    actions: [
        {
            regexToMatch: ['.*login'],
            timing: 'afterAudit',
            steps: [
                {
                    type: 'input',
                    target: '#username',
                    text: 'test@eye-able.com'
                },
                {
                    type: 'input',
                    text: 'mySecretPassword',
                    target: '#password'
                },
                {
                    type: 'click',
                    target: "button[type='submit']"
                },
                {
                    type: 'delay',
                    delay: '3000'
                }
            ]
        }
    ],
    dictionary: {
        1: ['Eye-Able']
    }
};

// base endpoints
export const storageUrl = 'https://eye-able-storage.b-cdn.net/';
export const reportStorageUrl = 'https://report-storage.eye-able.com/';

if (import.meta.env.VITE_USE_DEV_API) console.log('Using dev API');

// for production the CDN will redirect to the correct API server
// use origin to make it work with the dev and live servers
export const apiUrl = import.meta.env.PROD
    ? window.location.origin + '/api/'
    : import.meta.env.VITE_USE_LOCAL_API === 'true'
      ? 'http://127.0.0.1:3000/'
      : import.meta.env.VITE_USE_DEV_API === 'true'
        ? 'https://apiv3-dev.eye-able.com/'
        : 'https://apiv3.eye-able.com/';

// 'http://127.0.0.1:3000/'

export const userPdfStorageUrl = 'https://pdfcheck.eye-able.com/';

// api endpoints
export const api = {};

api.organisations = apiUrl + 'organisations';
api.orgFiles = api.organisations + '/files';
api.orgUsers = api.organisations + '/users';
api.orgLicenses = api.organisations + '/licenses';
api.reportEntries = apiUrl + 'reportEntries';
api.reportEntryConfig = api.reportEntries + '/config';
api.listAllLicenses = apiUrl + 'listAllLicenses';
api.users = apiUrl + 'users';
api.licenseConstraints = apiUrl + 'licenseConstraints';
api.login = apiUrl + 'authentication/login';
api.loginAsCustomer = apiUrl + 'authentication/loginAsCustomer';
api.signup = apiUrl + 'authentication/signup';
api.changePwd = apiUrl + 'authentication/changePw';
api.whoami = apiUrl + 'authentication/whoami';
api.pwLost = apiUrl + 'authentication/pwLost';
api.logout = apiUrl + 'authentication/logout';
api.conformanceTarget = apiUrl + 'conformanceTarget';
api.todo = apiUrl + 'todo';
api.todos = apiUrl + 'todos';
api.supportMail = apiUrl + 'supportMail';
api.offerRequest = apiUrl + 'offerRequest';
api.auditLicenses = apiUrl + 'auditLicenses';
api.assistLicenses = apiUrl + 'assistLicenses';
api.assistConfig = apiUrl + 'assistConfig';
api.assistTestLicense = api.assistLicenses + '/testLicense';
api.searchBase = apiUrl + 'search/';
api.jobs = apiUrl + 'jobs/';
api.jobsSimpleLangDocument = api.jobs + 'simpleLangDocument';
api.plainLanguage = apiUrl + 'simpleLanguage';
api.plainLanguageDocument = api.plainLanguage + '/document';
api.plainLanguageStream = api.plainLanguage + '/stream';
api.accessibilityStatement = apiUrl + 'accessibilityStatement';
api.ally = apiUrl + 'ally';
api.translateClients = apiUrl + 'translateClients';
api.translateClientsAll = api.translateClients + '/all';
api.jiraConfig = apiUrl + 'jira/config';
api.jiraApiBase = apiUrl + 'jira/api';
api.jiraProjects = api.jiraApiBase + '/projects';
api.jiraProject = api.jiraApiBase + '/project';
api.jiraIssue = api.jiraApiBase + '/issue';
api.jiraIssues = api.jiraApiBase + '/issues';
api.jiraUsers = api.jiraApiBase + '/users';
api.jiraIssueMetaData = api.jiraIssue + '/metadata';
api.jiraIssueTypes = api.jiraProject + '/issuetypes';
api.jiraComponents = api.jiraProject + '/components';
api.translationsDomain = apiUrl + 'manageTranslation/domain';
api.translationsSubpage = apiUrl + 'manageTranslation/subpage';
api.transDomainSearch = api.translationsDomain + '/search';
api.transDomainStats = api.translationsDomain + '/stats';
api.transDomainLangPairs = api.translationsDomain + '/langPairs';
api.transDomainAllTranslation = api.translationsDomain + '/allTranslation';
api.transSubpageListAll = api.translationsSubpage + '/allSubpages';
api.transSubpageAllTranslation = api.translationsSubpage + '/allTranslation';
api.transSubpageStats = api.translationsSubpage + '/stats';
api.translation = apiUrl + 'manageTranslation/translation';
api.createPdf = apiUrl + 'createPdf';
api.manualTest = apiUrl + 'manualTest';
api.reportEntryFiles = api.reportEntries + '/files';
api.transcription = apiUrl + 'transcription';
