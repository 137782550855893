import ProgressbarColors from '@helpers/progressbar-colors';
import { Box, Typography, useMediaQuery } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * CircularRatingWithInnerLabel displays a circular progress bar with a label inside.
 * @param rating{number|string} - The rating to display.
 * @param label{string} - The label to display.
 * @param small{boolean} - Whether to display the component in a smaller size.
 * @param dark{boolean} - Whether to use dark colors.
 * @param sx{Object} - Additional styles to apply.
 * @returns {JSX.Element}
 */
function CircularRatingWithInnerLabel({
    rating,
    prevRating = null,
    small = false,
    dark = false,
    label,
    sx
}) {
    const { t } = useTranslation();
    const xlUp = useMediaQuery(theme => theme.breakpoints.up('xl'));
    const value = Number.parseFloat(rating);

    label = label || t('overallRating');

    let size = 16;
    if (small) size = 13;
    else if (xlUp) size += 4;

    return (
        <Box alignItems='center' display='flex' justifyContent='center' position='relative' sx={sx}>
            <CircularProgress
                aria-label={label}
                size={size + 'rem'}
                sx={{
                    '& .MuiCircularProgress-svg': {
                        color: `${ProgressbarColors(value, dark)}`,
                        boxShadow: `inset 0 0 0 ${
                            small ? 17 : xlUp ? 26.5 : 21.5
                        }px rgb(229, 224, 224)`,
                        borderRadius: '50%',
                        backgroundColor: 'transparent',
                        strokeLinecap: 'round'
                    }
                }}
                value={value}
                variant='determinate'
            />

            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    fontSize={small ? '0.85rem' : { xs: '0.85rem', xl: '1rem' }}
                    fontWeight='bold'
                >
                    {label}
                </Typography>

                <Typography
                    fontSize={{ xs: small ? '1.6rem' : '2.6rem', xl: small ? '2.6rem' : '3.3rem' }}
                    fontWeight='bold'
                >
                    {value}%
                </Typography>

                {prevRating && (
                    <Typography
                        fontSize={small ? '1.2rem' : { xs: '1.2rem', xl: '1.4rem' }}
                        color='text.secondary'
                        sx={{ textDecoration: 'line-through', fontWeight: 500 }}
                    >
                        {prevRating}%
                    </Typography>
                )}

                <Typography
                    fontSize={small ? '0.85rem' : { xs: '0.85rem', xl: '1rem' }}
                >
                    {t('accessibilityText2')}
                </Typography>
            </Box>
        </Box>
    );
}

CircularRatingWithInnerLabel.propTypes = {
    label: PropTypes.string,
    rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    small: PropTypes.bool,
    dark: PropTypes.bool,
    sx: PropTypes.object
};

export default CircularRatingWithInnerLabel;
