// Material React Table with default settings and language detection
import {
    jiraConfigAtom,
    orgUsersAtom,
    selectedDomainAtom,
    todosDataAtom,
    userAtom
} from '@/global-store.jsx';
import { JiraIssueExport } from '@/pages/report/MyGoals/components/jira/IssueExport/JiraIssueExport';
import { scrollBarStylesOnWhite } from '@assets/theme/base/scrollbarStyles.jsx';
import StringAvatar from '@components/StringAvatar.jsx';
import { avatarColors } from '@helpers/constants.js';
import useIsXxlScreen from '@hooks/useIsXxlScreen.jsx';
import { useNotification } from '@hooks/useNotification.jsx';
import { useFetchJiraProjects, usePostTodo, useRemoveTodo, useUpdateTodos } from '@http/queries.js';
import {
    Avatar,
    Badge,
    Box,
    Button,
    CircularProgress,
    IconButton,
    ListSubheader,
    Menu,
    MenuItem,
    Tooltip
} from '@mui/material';
import { todoCategoryProps } from '@pages/report/MyGoals/components/utils';
import {
    IconChevronDown,
    IconDownload,
    IconExternalLink,
    IconFilterOff,
    IconLink,
    IconRefresh,
    IconStarFilled,
    IconUser,
    IconX
} from '@tabler/icons-react';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { atom, useAtom } from 'jotai';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { MRT_Localization_IT } from 'material-react-table/locales/it';
import { MRT_Localization_NL } from 'material-react-table/locales/nl';
import { MRT_Localization_PL } from 'material-react-table/locales/pl';
import { MRT_Localization_PT } from 'material-react-table/locales/pt';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { JiraIcon } from './customIcons';
import DynamicDialog from './DynamicDialog';

const locales = {
    de: MRT_Localization_DE,
    en: MRT_Localization_EN,
    es: MRT_Localization_ES,
    fr: MRT_Localization_FR,
    it: MRT_Localization_IT,
    nl: MRT_Localization_NL,
    pt: MRT_Localization_PT,
    pl: MRT_Localization_PL
};

const truncateStyles = {
    // overflow: 'hidden !important',
    whiteSpace: 'nowrap !important',
    textOverflow: 'ellipsis !important'
};

const tableTextFieldProps = () => {
    return {
        variant: 'outlined',
        margin: 'dense',
        size: 'small',
        sx: {
            'textAlign': 'left',
            'justifyContent': 'flex-start',
            'minWidth': '94px',
            'width': '100%',
            'maxWidth': '210px',

            '& .MuiSelect-outlined': {
                'px': '10px !important',
                'textAlign': 'left',
                'alignItems': 'flex-start',

                '& .MuiBox-root': {
                    'textAlign': 'left',
                    'justifyContent': 'flex-start',
                    'overflow': 'hidden',
                    'whiteSpace': 'nowrap',
                    'textOverflow': 'ellipsis',

                    '& span': {
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }
                }
            },

            '& .MuiOutlinedInput-root': {
                pr: '10px !important'
            }
        }
    };
};

const todoRowProcessingAtom = atom(null);

function OrgUsersMenu({
    open,
    anchorEl,
    handleClose,
    handleAssignee,
    handleRemove,
    orgUsers,
    todoId,
    assignee
}) {
    const { t } = useTranslation();
    const [user] = useAtom(userAtom);
    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            id='organisation users menu'
            onClick={handleClose}
            onClose={handleClose}
            open={open}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        'maxHeight': '400px',
                        'overflow': 'hidden',
                        'overflowY': 'auto',
                        'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        'mt': 1.5,
                        '& .MuiAvatar-root': {
                            width: 28,
                            height: 28,
                            ml: -0.5,
                            mr: 1
                        },
                        ...scrollBarStylesOnWhite
                    }
                }
            }}
            sx={{ pt: 1 }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
            <MenuItem onClick={() => handleRemove(todoId)}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    <Avatar>
                        <IconX />
                    </Avatar>

                    <Box>{t('removeFromMyGoals')}</Box>
                </Box>
            </MenuItem>

            <ListSubheader disableSticky sx={{ my: 0.5, background: '#fafafa' }}>
                {t('assignTo')}
            </ListSubheader>

            <MenuItem
                disabled={!assignee}
                onClick={() => handleAssignee(todoId, null)}
                sx={{ cursor: !assignee ? 'no-drop' : 'pointer' }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    <Avatar>
                        <IconUser />
                    </Avatar>

                    <Box>{t('unassigned')}</Box>
                </Box>
            </MenuItem>
            <MenuItem
                disabled={assignee?.id === user.id}
                onClick={() => handleAssignee(todoId, user.id)}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    <StringAvatar bgColor={avatarColors.me} small text={user.fullName} />

                    <Box fontWeight={600}>{user.fullName}</Box>
                </Box>
            </MenuItem>

            {orgUsers
                ?.sort((a, b) => a.surname?.localeCompare(b.surname))
                ?.map(orgUser => {
                    const fullName = `${orgUser.surname + ' ' || ''}${orgUser.name || ''}`;

                    return user.id === orgUser.id ? null : (
                        <MenuItem
                            disabled={assignee?.id === orgUser.id}
                            key={orgUser.id}
                            onClick={() => handleAssignee(todoId, orgUser.id)}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                                <StringAvatar
                                    bgColor={avatarColors[orgUser.orgAdmin]}
                                    small
                                    text={fullName}
                                />

                                <Box fontWeight={400}>{fullName}</Box>
                            </Box>
                        </MenuItem>
                    );
                })}
        </Menu>
    );
}

function TodoAssigneeSelector({
    todoId,
    orgUsers,
    todoAssignee,
    handleUpdateAssignee,
    handleRemoveBookmark
}) {
    const { t } = useTranslation();
    const [user] = useAtom(userAtom);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleAssigneeClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const fullName = `${todoAssignee?.surname + ' ' || ''}${todoAssignee?.name || ''}`;

    return (
        <>
            <Tooltip title={`${t('options')}...`}>
                <IconButton
                    aria-controls={open ? 'organisation users menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup='true'
                    onClick={handleAssigneeClick}
                >
                    <Badge
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <Avatar
                                style={{
                                    width: 16,
                                    height: 16,
                                    border: `2px solid white`,
                                    background: '#f6bf4a'
                                }}
                            >
                                <IconChevronDown />
                            </Avatar>
                        }
                    >
                        {todoAssignee ? (
                            <StringAvatar
                                avatarSize={28}
                                bgColor={
                                    todoAssignee.id === user.id
                                        ? avatarColors.me
                                        : avatarColors[todoAssignee.orgAdmin]
                                }
                                small
                                text={fullName}
                            />
                        ) : (
                            <Avatar style={{ width: 28, height: 28 }}>
                                <IconUser />
                            </Avatar>
                        )}
                    </Badge>
                </IconButton>
            </Tooltip>

            <OrgUsersMenu
                anchorEl={anchorEl}
                assignee={todoAssignee}
                handleAssignee={handleUpdateAssignee}
                handleClose={handleMenuClose}
                handleRemove={handleRemoveBookmark}
                open={open}
                orgUsers={orgUsers}
                todoId={todoId}
            />
        </>
    );
}

function TodoJiraSelector({ todoId, todo, jiraConfig, handleRemoveBookmark, exportTodoToJira }) {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleAssigneeClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleRemove = () => {
        handleRemoveBookmark(todoId);
    };

    const handleExport = () => {
        exportTodoToJira(todoId, todo);
    };

    const openInJira = () => {
        window.open(
            `https://${jiraConfig?.domain}.atlassian.net/browse/${todo?.jira?.key}`,
            '_blank'
        );
    };

    return (
        <>
            <Tooltip title={`${t('options')}...`}>
                <IconButton
                    aria-controls={open ? 'organisation users menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup='true'
                    onClick={handleAssigneeClick}
                >
                    <Badge
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                            <Avatar
                                style={{
                                    width: 16,
                                    height: 16,
                                    border: `2px solid white`,
                                    background: '#f6bf4a'
                                }}
                            >
                                <IconChevronDown />
                            </Avatar>
                        }
                    >
                        <Avatar style={{ width: 28, height: 28 }}>
                            <JiraIcon grayscale={!todo.jira} />
                        </Avatar>
                    </Badge>
                </IconButton>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                id='organisation users menu'
                onClick={handleMenuClose}
                onClose={handleMenuClose}
                open={open}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            'maxHeight': '400px',
                            'overflow': 'hidden',
                            'overflowY': 'auto',
                            'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            'mt': 1.5,
                            '& .MuiAvatar-root': {
                                width: 28,
                                height: 28,
                                ml: -0.5,
                                mr: 1
                            },
                            ...scrollBarStylesOnWhite
                        }
                    }
                }}
                sx={{ pt: 1 }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                <MenuItem onClick={handleRemove}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                        <Avatar>
                            <IconX />
                        </Avatar>

                        <Box>{t('removeFromMyGoals')}</Box>
                    </Box>
                </MenuItem>
                {!todo.jira ? (
                    <MenuItem onClick={handleExport}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                            <Avatar>
                                <IconLink />
                            </Avatar>

                            <Box>{t('exportToJira')}</Box>
                        </Box>
                    </MenuItem>
                ) : (
                    <MenuItem onClick={openInJira}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                            <Avatar>
                                <IconExternalLink />
                            </Avatar>

                            <Box>{t('opneInJira')}</Box>
                        </Box>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}

function TodoCell({
    cellData,
    rowId,
    bookmarksFilterIdKey,
    bookmarksCategory,
    handleRemoveTodo,
    handlePostTodo,
    handleExportDialogOpen
}) {
    const { t } = useTranslation();
    const notification = useNotification();

    // use an atom to store the rowId of the row that is currently being processed
    const [todoRowProcessing, setTodoRowProcessing] = useAtom(todoRowProcessingAtom);
    const [selectedDomain] = useAtom(selectedDomainAtom);
    const [orgUsers] = useAtom(orgUsersAtom);
    const [user] = useAtom(userAtom);
    const [todosData] = useAtom(todosDataAtom);
    const [jiraConfig] = useAtom(jiraConfigAtom);
    const updateTodosMutation = useUpdateTodos();

    const isExportableToJira = jiraConfig && !!todosData?.jira?.jiraProjectsMap?.[selectedDomain];

    function getTodoId() {
        let _todoId = undefined;
        const todos = todosData?.todos;
        if (todos) {
            _todoId = Object.keys(todos).find(
                key =>
                    todos[key].domain === selectedDomain &&
                    todos[key].category === bookmarksCategory &&
                    (todos[key].filterId ?? todos[key].props?.filterId) ===
                        cellData[bookmarksFilterIdKey]
            );
        }
        return _todoId;
    }

    async function handleUpdateAssignee(todoId, assignee) {
        if (todoRowProcessing) {
            notification.warning(t('requestProcessingWait'));
            return;
        }

        setTodoRowProcessing(rowId);

        const todos = todosData?.todos;

        if (todos && todos[todoId]) {
            const history = todos[todoId]?.history ?? [];

            // type 4: assignee changed
            history.push({
                type: 4,
                activityDate: Date.now(),
                modifier: user.id,
                props: { assignedFrom: todos[todoId]?.assignee, assignedTo: assignee }
            });
            const todosToUpdate = [{ todoId, assignee, history }];
            try {
                await updateTodosMutation.mutateAsync({
                    todosToUpdate
                });
            } catch (error) {
                console.error('updateTodosMutation', 'ReactTable.jsx', 'error', error);
            }
        } else {
            console.error('updateTodosMutation', 'ReactTable.jsx', 'no todosData found');
        }

        setTodoRowProcessing(null);
    }

    function handleRemoveBookmark(todoId) {
        handleBookmarks(cellData, todoId, false, rowId);
    }

    async function exportTodoToJira(todoId, todo) {
        if (todo.jira) return;
        if (todoRowProcessing) {
            notification.warning(t('requestProcessingWait'));
            return;
        }

        const goToPage = todoCategoryProps[todo.category].url(todo);
        const categoryTitle =
            todoCategoryProps[todo.category].label ||
            t(todoCategoryProps[todo.category].i18nLabelKey);
        const extraJiraProps = {
            goToPage,
            title: cellData?.title ?? 'Eye Able - ' + categoryTitle,
            domain: selectedDomain
        };
        handleExportDialogOpen({
            jira: { ...todo, todoId, ...extraJiraProps },
            json: { ...todo, todoId }
        });
    }

    async function handleBookmarks(tableEntry, todoId, checked, rowId) {
        if (todoRowProcessing) {
            notification.warning(t('requestProcessingWait'));
            return;
        }

        const category = bookmarksCategory;

        setTodoRowProcessing(rowId);

        if (checked) {
            await handlePostTodo(tableEntry, isExportableToJira);
        } else if (todoId) {
            await handleRemoveTodo(todoId, category);
        }

        setTodoRowProcessing(null);
    }

    const todoId = getTodoId();

    const todoAssignee = !todoId
        ? null
        : orgUsers?.find(user => user.id === todosData?.todos[todoId]?.assignee);

    if (todoRowProcessing === rowId) return <CircularProgress />;

    return todoId ? (
        isExportableToJira ? (
            <TodoJiraSelector
                handleRemoveBookmark={handleRemoveBookmark}
                todoId={todoId}
                todo={todosData?.todos[todoId]}
                jiraConfig={jiraConfig}
                exportTodoToJira={exportTodoToJira}
            />
        ) : orgUsers?.length > 1 ? (
            <TodoAssigneeSelector
                handleRemoveBookmark={handleRemoveBookmark}
                handleUpdateAssignee={handleUpdateAssignee}
                orgUsers={orgUsers}
                todoAssignee={todoAssignee}
                todoId={todoId}
            />
        ) : (
            <Tooltip title={t('removeFromMyGoals')}>
                <IconButton onClick={() => handleBookmarks(cellData, todoId, false, rowId)}>
                    <Avatar style={{ width: 30, height: 30, color: '#000', background: '#ffd050' }}>
                        <IconStarFilled color='#ffd050' />
                    </Avatar>
                </IconButton>
            </Tooltip>
        )
    ) : (
        <Tooltip title={t('addToMyGoals')}>
            <IconButton onClick={() => handleBookmarks(cellData, todoId, true, rowId)}>
                <Avatar style={{ width: 30, height: 30 }}>
                    <IconStarFilled />
                </Avatar>
            </IconButton>
        </Tooltip>
    );
}

// * @param {boolean | string} props.enableRowVirtualization - Whether to enable row virtualization, 'auto' will enable auto mode.

/**
 * ReactTable component to render a table with given configurations.
 *
 * @param {Object} props - ReactTable props.
 * @param {Array} props.columns - An array of column objects.
 * @param {Array} props.data - An array of row data.
 * @param {Object} [props.initialState] - Initial state of the table.
 * @param {boolean} [props.topToolbar] - Should the top Toolbar be shown or not.
 * @param {string} [props.filterFontsize] - Custom font size for filters.
 * @param {string} [props.exportFilename='table-export'] - Filename for the exported table data.
 * @param {Array<string>} [props.ignoredDataKeysOnExport=null] - Keys to be ignored during data export.
 * @param {Array<string>} [props.onlyDataKeysOnExport=null] - Only these keys will be included during data export. Will take precedence over `ignoredDataKeysOnExport` if both are defined.
 * @param {string|null} props.headCellFontSize - Font size for the header cells.
 * @param {boolean} [props.truncatedHeadCells=false] - Indicates whether to truncate header cells.
 * @param {boolean} [props.truncatedHeadCellMaxWidth='130px'] - Indicates whether to truncate header cells.
 * @param {boolean} props.enableBookmarks - Indicates whether to enable bookmarks.
 * @param {Object} props.bookmarksCategory - Bookmarks category.
 * @param {string} props.bookmarksFilterIdKey - Bookmarks filter id key.
 * @param {Array<string>} props.bookmarksIncludedKeys - Bookmarks included keys.
 * @param {function} props.renderDetailPanel - Render function for the detail panel.
 * @param {boolean} props.refetchDataBtn - Hide/show refetch button in bottom toolbar.
 * @param {function} props.handleRefetchData - Function to refetch table data
 * @param {function} props.customExport - Use Custom Export function instead the defined onExport
 * @param {function} props.expandFirstRow - Wether to expand or not the first row

 * @param {Object} props.props - Rest of the props.


 */

function ReactTable({
    columns,
    data,
    initialState,
    topToolbar = false,
    filterFontsize = '',
    disableExport = false,
    exportFilename = 'table-export',
    ignoredDataKeysOnExport = null,
    onlyDataKeysOnExport = null,
    headCellFontSize = null,
    truncatedHeadCells = false,
    truncatedHeadCellMaxWidth = '130px',
    enableBookmarks = false,
    bookmarksCategory = '',
    bookmarksFilterIdKey = 'filterId',
    bookmarksIncludedKeys = [],
    renderDetailPanel = null,
    maxHeight = 'calc(100vh - 220px)',
    paginationStartLimit = 100,
    refetchDataBtn = false,
    handleRefetchData = null,
    //enableRowVirtualization = false,
    customExport = false,
    expandFirstRow = true,
    ...props
}) {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const jiraIssueExportRef = useRef();
    const notification = useNotification();

    const isXxlScreen = useIsXxlScreen();

    const [selectedDomain] = useAtom(selectedDomainAtom);

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [renderKey, setRenderKey] = useState(0);
    const [refetchBtnDisabled, setRefreshBtnDisabled] = useState(false);
    const [jiraExportDialogOpen, setJiraExportDialogOpen] = useState({ open: false });
    const { data: jiraProjects } = useFetchJiraProjects();

    function handleExportDialogOpen(data) {
        setJiraExportDialogOpen({ open: true, data });
    }

    function handleExportDialogClose() {
        setJiraExportDialogOpen({ open: false });
    }

    const handleExportDialogSubmit = async () => {
        const response = await postTodoToJIRA();
        if (response) setJiraExportDialogOpen({ open: false });
    };

    const postTodoToJIRA = async () => {
        if (!jiraIssueExportRef?.current?.handlePost) return false;
        try {
            const jiraResponse = await jiraIssueExportRef?.current?.handlePost();
            if (!jiraResponse) return false;

            const { id, key } = jiraResponse;

            const todoData = jiraExportDialogOpen.data.json;
            let updatedTodo = { jira: { id, key } };
            if (todoData?.todoId) updatedTodo = { ...updatedTodo, todoId: todoData?.todoId };
            else updatedTodo = { ...updatedTodo, ...todoData };

            const response = await handleSaveTodoToJSON(updatedTodo);
            if (response) {
                setJiraExportDialogOpen({ open: false });
            }
            notification.success(t('jiraIssuePostSuccess'), 15000);
            return !!response;
        } catch (error) {
            console.error('error_postTodoToJira', error);
            notification.error(t('jiraIssuePostFailed'), 15000);

            return false;
        }
    };

    /* const _enableRowVirtualization = useMemo(() => {
        return data?.length > 500 ?? false;
    }, [data?.length]); */

    const _enableRowVirtualization = false;

    const _pagination = data?.length > paginationStartLimit;

    const rerenderTable = useCallback(
        function rerenderTableCallback(selector) {
            const currentUrl = window.location.href;

            // check if the elements are rendered
            setTimeout(() => {
                // do not run if user has navigated away from the page
                if (currentUrl !== window.location.href) return;
                if (!data.length) return;
                // check if the table is filtered and there are no visible rows
                if (
                    // TODO: add translations support
                    document.evaluate(
                        '//div[contains(., "Keine Ergebnisse")]',
                        document,
                        null,
                        XPathResult.ANY_TYPE,
                        null
                    ) ||
                    document.evaluate(
                        '//div[contains(., "No records")]',
                        document,
                        null,
                        XPathResult.ANY_TYPE,
                        null
                    )
                )
                    return;

                const elements = document.querySelectorAll(selector);

                if (!elements?.length) {
                    console.info('react table: elements not found', selector);
                    setRenderKey(prev => prev + 1);
                    rerenderTable(selector); // recheck again if there was an error
                }
            }, 600);
        },
        [data.length]
    );

    const [todosData] = useAtom(todosDataAtom);
    const [user] = useAtom(userAtom);
    const [jiraConfig] = useAtom(jiraConfigAtom);

    const linkedJiraProject = useMemo(() => {
        if (!jiraConfig || !jiraProjects || !todosData?.jira?.jiraProjectsMap) return undefined;

        const jiraProjectKey = todosData?.jira?.jiraProjectsMap?.[selectedDomain];
        return jiraProjects?.values?.find(project => project.key == jiraProjectKey);
    }, [jiraConfig, jiraProjects, todosData?.jira?.jiraProjectsMap, selectedDomain]);

    const postTodoMutation = usePostTodo();
    const removeTodoMutation = useRemoveTodo();
    const updateTodosMutation = useUpdateTodos();

    const handleSaveTodoToJSON = useCallback(
        async todo => {
            let response = undefined;
            try {
                if (todo.todoId)
                    response = await updateTodosMutation.mutateAsync({
                        todosToUpdate: [{ ...todo }]
                    });
                else response = await postTodoMutation.mutateAsync(todo);
                return response;
            } catch (error) {
                console.error('handleSaveTodoToJSON', 'error', error);
            }
        },
        [postTodoMutation, updateTodosMutation]
    );

    const handlePostTodo = useCallback(
        async (tableEntry, isExportableToJira = false) => {
            const category = bookmarksCategory;

            let newTodo;
            newTodo = {
                filterId: tableEntry[bookmarksFilterIdKey],
                props: {},
                category,
                reporter: user.id,
                assignee: user.id,
                resolved: false,
                dateCreated: Date.now(),
                history: [
                    {
                        type: 1,
                        activityDate: Date.now(),
                        modifier: user.id
                    }
                ]
            };

            for (const key of bookmarksIncludedKeys) {
                newTodo.props[key] = tableEntry[key];
            }

            if (isExportableToJira && jiraConfig?.alwaysExport === 1) {
                const goToPage = todoCategoryProps[newTodo.category].url(newTodo);
                const categoryTitle =
                    todoCategoryProps[newTodo.category].label ||
                    t(todoCategoryProps[newTodo.category].i18nLabelKey);
                const extraJiraProps = {
                    goToPage,
                    title: tableEntry?.title ?? 'Eye Able - ' + categoryTitle,
                    domain: selectedDomain
                };
                handleExportDialogOpen({
                    jira: { ...newTodo, ...extraJiraProps },
                    json: { ...newTodo }
                });
            } else {
                const response = await handleSaveTodoToJSON(newTodo);
                return response;
            }
        },
        [
            bookmarksCategory,
            bookmarksFilterIdKey,
            bookmarksIncludedKeys,
            handleSaveTodoToJSON,
            jiraConfig?.alwaysExport,
            selectedDomain,
            t,
            user.id
        ]
    );

    const handleRemoveTodo = useCallback(
        async (todoId, category) => {
            console.log('remove todo', todoId);
            try {
                await removeTodoMutation.mutateAsync(todoId);
            } catch (error) {
                console.error('removeTodoMutation', category, 'error', error);
            }
        },
        [removeTodoMutation]
    );

    function onExport(rows) {
        let columnHeaders = [];

        for (const column of columns) {
            const columnKey = column.accessorKey || column.id;

            if (
                (onlyDataKeysOnExport?.length &&
                    onlyDataKeysOnExport.includes(columnKey) &&
                    !column.ignoreOnExport) ||
                (ignoredDataKeysOnExport?.length &&
                    !ignoredDataKeysOnExport.includes(columnKey) &&
                    !column.ignoreOnExport) ||
                (!onlyDataKeysOnExport?.length &&
                    !ignoredDataKeysOnExport?.length &&
                    !column.ignoreOnExport)
            ) {
                columnHeaders.push({
                    key: columnKey,
                    displayLabel: column.header
                });
            }
        }

        let filename = location.pathname.toLowerCase().includes('/report/')
            ? `${exportFilename}__${selectedDomain}`
            : exportFilename;

        filename = `${filename}__${new Date().toISOString().split('T')[0]}`;

        const csvConfig = mkConfig({
            quoteStrings: false,
            fieldSeparator: ',',
            filename,
            decimalSeparator: i18n.resolvedLanguage === 'de' ? ',' : '.',
            useBom: true,
            columnHeaders
        });

        // Converts your Array<Object> to a CsvOutput string based on the configs
        let test = rows.map(row => row.original);
        console.log('test', test);
        console.log('headers', columnHeaders);
        const csv = generateCsv(csvConfig)(rows.map(row => row.original));

        download(csvConfig)(csv);
    }

    const filterPlaceholderSize = filterFontsize
        ? { fontSize: `${filterFontsize} !important` }
        : {};

    const headCellProps = headCellFontSize ? { fontSize: `${headCellFontSize} !important` } : {};

    const initialTableState = useMemo(() => {
        return {
            pagination: { pageSize: 100, pageIndex: 0 },
            density: isXxlScreen ? 'comfortable' : 'compact',
            showColumnFilters: true,
            showGlobalFilter: false,
            expanded: { 0: expandFirstRow },
            ...initialState
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isXxlScreen, initialState]);

    const _columns = useMemo(() => {
        if (enableBookmarks)
            return [
                {
                    size: 56,
                    maxSize: 56,
                    accessorKey: 'todoId',
                    Cell: ({ cell }) => {
                        return (
                            <TodoCell
                                bookmarksCategory={bookmarksCategory}
                                bookmarksData={todosData}
                                bookmarksFilterIdKey={bookmarksFilterIdKey}
                                bookmarksIncludedKeys={bookmarksIncludedKeys}
                                cellData={cell.row.original}
                                rowId={bookmarksCategory + cell.row.id}
                                handleExportDialogOpen={handleExportDialogOpen}
                                handleRemoveTodo={handleRemoveTodo}
                                handlePostTodo={handlePostTodo}
                            />
                        );
                    },
                    header: '',
                    enableColumnFilter: false,
                    enableColumnSort: false,
                    enableColumnActions: false,
                    enableColumnDragging: false,
                    enableColumnFilterModes: false,
                    enableColumnOrdering: false,
                    enableHiding: false,
                    enableSorting: false
                },
                ...columns
            ];
        else return columns;
    }, [
        enableBookmarks,
        columns,
        bookmarksCategory,
        todosData,
        bookmarksFilterIdKey,
        bookmarksIncludedKeys,
        handleRemoveTodo,
        handlePostTodo
    ]);

    // force rerender on prop changes
    const tableKey = useMemo(() => {
        let _tableKey = isXxlScreen ? 'large' : 'small';
        _tableKey += _enableRowVirtualization ? 'virtual' : 'nonVirtual';
        _tableKey += enableBookmarks ? 'bookmarks' : 'noBookmarks';
        _tableKey += data?.length || 0;
        _tableKey += renderDetailPanel ? 'detailPanel' : 'noDetailPanel';
        _tableKey += renderKey;
        _tableKey += i18n.resolvedLanguage;
        return _tableKey;
    }, [
        i18n.resolvedLanguage,
        isXxlScreen,
        _enableRowVirtualization,
        enableBookmarks,
        data?.length,
        renderDetailPanel,
        renderKey
    ]);

    useEffect(() => {
        // rerender table if there are now visible rows
        rerenderTable('tbody td');
    }, [rerenderTable]);

    useEffect(() => {
        if (renderDetailPanel) {
            // force rerender if the expand icon is not rendered
            rerenderTable('svg[data-testid="ExpandMoreIcon"]');
        }
    }, [rerenderTable, renderDetailPanel, enableBookmarks]);

    const refetchDataClicked = () => {
        handleRefetchData();
        setRefreshBtnDisabled(true);
        setTimeout(() => {
            setRefreshBtnDisabled(false);
        }, 10000);
    };

    return (
        <>
            <MaterialReactTable
                initialState={initialTableState}
                columns={_columns}
                data={data}
                enablePagination={_pagination}
                paginationDisplayMode='pages'
                muiPaginationProps={{
                    variant: 'outlined',
                    showRowsPerPage: false
                }}
                enableColumnActions
                enableDensityToggle={false}
                enableRowVirtualization={_enableRowVirtualization}
                enableStickyHeader
                enableTableFooter={false}
                enableTopToolbar={topToolbar}
                key={tableKey}
                localization={locales[i18n.resolvedLanguage] || locales.en}
                onIsFullScreenChange={() => {
                    if (isFullScreen) {
                        document.body.style.overflow = 'auto';
                    } else document.body.style.overflow = 'hidden';

                    setIsFullScreen(!isFullScreen);
                }}
                defaultColumn={{
                    Header: ({ column }) => (
                        <Tooltip
                            title={column.columnDef.header}
                            placement='top'
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, 3]
                                            }
                                        }
                                    ]
                                }
                            }}
                        >
                            <span>{column.columnDef.header}</span>
                        </Tooltip>
                    )
                }}
                renderBottomToolbarCustomActions={({ table }) => {
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '1rem',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                pt: 0.15,
                                flexGrow: 1
                            }}
                        >
                            <Button
                                onClick={() =>
                                    table.resetColumnFilters() && table.resetGlobalFilter()
                                }
                                size='small'
                                startIcon={<IconFilterOff />}
                                disabled={!table.getState().columnFilters?.length}
                            >
                                {t('resetFilters')}
                            </Button>
                            {!disableExport && (
                                <Button
                                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                                    onClick={() =>
                                        customExport
                                            ? customExport()
                                            : onExport(table.getPrePaginationRowModel().rows)
                                    }
                                    size='small'
                                    startIcon={<IconDownload />}
                                >
                                    {t('exportAllRows')}
                                </Button>
                            )}

                            {refetchDataBtn && (
                                <Button
                                    disabled={refetchBtnDisabled || !handleRefetchData}
                                    onClick={refetchDataClicked}
                                    size='small'
                                    startIcon={<IconRefresh />}
                                    aria-label={t('refreshData')}
                                >
                                    {t('refreshData')}
                                </Button>
                            )}
                            <Box fontWeight={500} ml='auto' pr={1.5}>
                                {table.getPrePaginationRowModel().rows.length
                                    ? t('tableRowsCounter', {
                                          from:
                                              1 +
                                              table.getState().pagination.pageSize *
                                                  table.getState().pagination.pageIndex,
                                          to: Math.min(
                                              table.getPrePaginationRowModel().rows.length,
                                              table.getState().pagination.pageSize *
                                                  (table.getState().pagination.pageIndex + 1)
                                          ),
                                          total: table.getPrePaginationRowModel().rows.length
                                      })
                                    : t('noRows')}
                            </Box>
                        </Box>
                    );
                }}
                positionExpandColumn='first'
                renderDetailPanel={renderDetailPanel}
                rowVirtualizerOptions={{
                    overscan: 25, //adjust the number or rows that are rendered above and below the visible area of the table
                    estimateSize: () => 86 //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
                }}
                {...props}
                muiBottomToolbarProps={{
                    elevation: 0, //change the mui box shadow
                    sx: {
                        'minHeight': '3.25rem',
                        'backgroundColor': '#fff',
                        'borderTopRightRadius': '0 !important',
                        'borderTopLeftRadius': '0 !important',
                        'border': 'none',
                        'boxShadow': 'none',
                        'borderTop': '2px solid #333',

                        '& > .MuiBox-root': {
                            'display': 'flex',
                            'alignItems': 'center',
                            'flexWrap': 'wrap',

                            '& div:nth-of-type(2)': {
                                marginLeft: 'auto'
                            }
                        }
                    }
                }}
                muiDetailPanelProps={{
                    sx: {
                        'width': '100%',
                        'p': 0,
                        'bgcolor': '#fff !important',
                        '& > div': {
                            maxWidth: '100%'
                        }
                    }
                }}
                muiEditTextFieldProps={tableTextFieldProps}
                muiFilterTextFieldProps={tableTextFieldProps}
                muiSearchTextFieldProps={tableTextFieldProps}
                muiTableBodyCellProps={{
                    sx: {
                        'fontWeight': 500,
                        'whiteSpace': 'normal',
                        'fontSize': '15px',
                        'minWidth': '44px',
                        'maxWidth': { xs: '260px', md: '380px', xxl: '540px' },
                        'textAlign': 'center',
                        '&:first-of-type': {
                            textAlign: 'left'
                        },
                        '&[data-pinned="true"]::before': {
                            backgroundColor: 'rgba(0, 0, 0, 0.0) !important'
                        }
                    }
                }}
                muiTableBodyProps={{
                    elevation: 0,
                    ...props?.muiTableBodyProps,
                    sx: {
                        '& .MuiList-root': {
                            background: '#fafafa !important',
                            outline: '2px solid red'
                        },
                        'background': '#fff !important',
                        'borderRadius': 0,
                        'border': 'none',
                        'boxShadow': 'none',
                        '& tr td:first-of-type': {
                            whiteSpace: 'normal'
                        },
                        //stripe the rows, make odd rows a darker color
                        '& tr:nth-of-type(odd)': {
                            'backgroundColor': '#f5f5f5 !important',
                            '& td:first-of-type': {
                                backgroundColor: '#f5f5f5f1 !important'
                            }
                        },
                        '& tr:nth-of-type(even)': {
                            'backgroundColor': '#fff !important',
                            '& td:first-of-type': {
                                backgroundColor: '#fffffff1 !important'
                            }
                        },
                        '& tr:not(.Mui-TableBodyCell-DetailPanel):hover > td': {
                            backgroundColor: '#ffff !important'
                        },
                        '& tr:not(.Mui-TableBodyCell-DetailPanel):hover td:first-of-type': {
                            backgroundColor: '#ffff !important'
                        },
                        ...props?.muiTableBodyProps?.sx
                    }
                }}
                muiTableContainerProps={{
                    elevation: 0, //change the mui box shadow
                    ...props?.muiTableContainerProps,
                    sx: {
                        background: '#fff !important',
                        borderRadius: 0,
                        minHeight: { xs: '320px', md: '420px', xxl: '520px' },
                        maxHeight: maxHeight,
                        ...scrollBarStylesOnWhite,
                        ...props?.muiTableContainerProps?.sx
                    }
                }}
                muiTableFooterCellProps={{}}
                muiTableFooterProps={{}}
                muiTableHeadCellProps={({ column }) => {
                    return {
                        ...props?.muiTableHeadCellProps,
                        'aria-label': column.columnDef.header,
                        'sx': {
                            '& .MuiList-root': {
                                background: '#fafafa !important',
                                outline: '2px solid red'
                            },
                            'minWidth': '44px',
                            '& .Mui-TableHeadCell-Content-Wrapper': truncatedHeadCells
                                ? {
                                      ...truncateStyles,
                                      maxWidth: truncatedHeadCellMaxWidth
                                  }
                                : {},
                            '& .MuiTableSortLabel-root': {
                                ml: '6px',
                                ...truncateStyles
                            },
                            '& .Mui-TableHeadCell-Content-Labels': {
                                ...truncateStyles,
                                ...headCellProps
                            },
                            '& .Mui-TableHeadCell-Content': {
                                textAlign: 'center',
                                justifyContent: 'center'
                            },
                            'textAlign': 'center',
                            'justifyContent': 'center',
                            '&:first-of-type': {
                                '& .Mui-TableHeadCell-Content': {
                                    justifyContent: 'flex-start',
                                    textAlign: 'left'
                                },
                                'justifyContent': 'flex-start',
                                'textAlign': 'left'
                            },
                            '&[data-pinned="true"]::before': {
                                backgroundColor: 'rgba(0, 0, 0, 0.0) !important'
                            },
                            ...truncateStyles,
                            ...props?.muiTableHeadCellProps?.sx
                        }
                    };
                }}
                muiTableHeadProps={{
                    ...props?.muiTableHeadProps,
                    sx: {
                        'opacity': 1,
                        'zIndex': 5,
                        '& th': {
                            backgroundColor: '#fff',
                            ...truncateStyles
                        },

                        '& .MuiInputBase-root': {
                            ...filterPlaceholderSize
                        },

                        '& .MuiInputBase-input': {
                            minWidth: '24px !important'
                        },

                        '& .MuiCollapse-wrapper .MuiBox-root': {
                            gap: '0.5rem !important'
                        },

                        '& .MuiCollapse-wrapper .MuiInputBase-input': {
                            pr: 0
                        },

                        '& .MuiTableCell-head': {
                            borderBottom: '2px solid #333',
                            ...truncateStyles
                        },

                        ...props?.muiTableHeadProps?.sx
                    }
                }}
                muiTablePaperProps={({ table }) => ({
                    style: {
                        zIndex: table.getState().isFullScreen ? 999999 : undefined
                    },
                    elevation: 0, //change the mui box shadow
                    ...props?.muiTablePaperProps,
                    sx: {
                        width: '100%',
                        background: '#fff !important',
                        ...props?.muiTablePaperProps?.sx
                    }
                })}
                muiTopToolbarProps={{
                    elevation: 0, //change the mui box shadow
                    sx: {
                        'backgroundColor': '#fff',
                        'border': 'none',
                        'boxShadow': 'none',

                        '& > .MuiBox-root': {
                            'display': 'flex',
                            'alignItems': 'center',
                            'flexWrap': 'wrap',

                            '& div:nth-of-type(2)': {
                                marginLeft: 'auto'
                            }
                        }
                    }
                }}
                state={{
                    isFullScreen,
                    ...props?.state
                }}
            />

            {jiraExportDialogOpen?.open ? (
                <DynamicDialog
                    hideActivator
                    maxWidth='md'
                    onCancel={handleExportDialogClose}
                    onConfirm={handleExportDialogSubmit}
                    overwriteOpen={jiraExportDialogOpen?.open}
                    confirmBtnProps={{ color: 'success' }}
                    confirmBtnIcon={<IconExternalLink />}
                    confirmBtnText={t('export')}
                    title={t('exportToJira')}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 2 }}>
                        {/* <Box>{t('exportToJiraPorject')}</Box> */}
                        {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <JiraProjectInfo project={jiraProject} />
                        </Box> */}
                        <JiraIssueExport
                            todo={jiraExportDialogOpen.data.jira}
                            ref={jiraIssueExportRef}
                            jiraProject={linkedJiraProject}
                            linkedJiraUserID={todosData?.jira?.jiraUsersMap?.[user.id]}
                        />
                    </Box>
                </DynamicDialog>
            ) : null}
        </>
    );
}

ReactTable.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    disableExport: PropTypes.bool,
    exportFilename: PropTypes.string,
    filterFontsize: PropTypes.string,
    headCellFontSize: PropTypes.string,
    ignoredDataKeysOnExport: PropTypes.arrayOf(PropTypes.string),
    initialState: PropTypes.object,
    topToolbar: PropTypes.bool,
    truncatedHeadCells: PropTypes.bool,
    truncatedHeadCellMaxWidth: PropTypes.string,
    refetchDataBtn: PropTypes.bool,
    handleRefetchData: PropTypes.func
};

ReactTable.defaultProps = {
    columns: [],
    data: [],
    initialState: {},
    disableExport: false,
    topToolbar: false,
    filterFontsize: null,
    headCellFontSize: null,
    truncatedHeadCells: false,
    truncatedHeadCellMaxWidth: '130px',
    exportFilename: 'table-export',
    ignoredDataKeysOnExport: null,
    refetchDataBtn: false,
    handleRefetchData: null
};

export default ReactTable;
